import { observable, action } from 'mobx';
import moment from 'moment';
import Entity, { ISerializable } from '../Entity';
import BaseFilter from '../BaseFilter';
import Product from '../Product/Product';
import Contact from '../contact/contact';

export type PartnershipOfferFeeType = 'percent' | 'value';
export type PartnershipOfferProductType = 'all' | 'selected';
export type PartnershipOfferPartnerType = 'all' | 'selected';
export type PartnershipOfferPurchaseType = 'any' | 'first' | 'repeat';
export type PartnershipOfferUrl = { id: string; name: string; url: string };

export default class PartnershipOffer extends Entity implements ISerializable {
    constructor(t?: Partial<PartnershipOffer>) {
        super();
        if (t) this.update(t);
    }

    @observable title: string;
    /**
     * @deprecated use urls instead
     */
    @observable url: string;
    @observable urls: PartnershipOfferUrl[];
    @observable description: string;
    @observable isActive: boolean;
    @observable createdDate: moment.Moment;
    @observable updatedDate?: moment.Moment;
    /**
     * @deprecated use levels instead
     */
    @observable fee: number;
    @observable feeType: PartnershipOfferFeeType;
    @observable productType: PartnershipOfferProductType;
    @observable partnerType: PartnershipOfferPartnerType;
    @observable purchaseType: PartnershipOfferPurchaseType;
    @observable products: Product[];
    @observable partners: Contact[];
    @observable levels: number[];

    toJson() {
        return {
            id: this.id,
            title: this.title,
            url: this.url,
            urls: this.urls,
            description: this.description,
            isActive: this.isActive,
            feeType: this.feeType,
            productType: this.productType,
            partnerType: this.partnerType,
            purchaseType: this.purchaseType,
            productIds: this.products.map(p => p.id),
            partnerIds: this.partners.map(p => p.id),
            levels: this.levels
        }
    }

    @action update(partnership: Partial<PartnershipOffer>) {
        super.update(partnership);
    }

    static fromJson(json: any): PartnershipOffer {
        return new PartnershipOffer({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            urls: json.urlsJson ? JSON.parse(json.urlsJson) : undefined,
            products: json.productLinks ? json.productLinks.map((x: any) => Product.fromJson(x.product)) : undefined,
            partners: json.partnerLinks ? json.partnerLinks.map((x: any) => Contact.fromJson(x.partner)) : undefined
        });
    }
}

export class PartnershipOfferFilter extends BaseFilter<PartnershipOffer> {
    constructor(filter?: Partial<PartnershipOfferFilter>) {
        super();
        if (filter) this.update(filter);
    }

    createdDateFrom: moment.Moment;
    createdDateTo: moment.Moment;
    feeFrom: number;
    feeTo: number;
    productIds: string[];

    update(changes: Partial<PartnershipOfferFilter>) {
        super.update(changes);
    }
}